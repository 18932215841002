<div class="user-info">
  <input
    type="file"
    (change)="uploadAvatar($event)"
    class="hidden-input"
    accept="{{ fileTypes }}"
    #avatarUpload
  />

  <div
    [ngClass]="{ uploader: isUploader }"
    (click)="isUploader && avatarUpload.click()"
  >
    @if (user && user.avatarUrl !== "") {
      <img
        class="avatar"
        src="{{ user.avatarUrl }}"
        [matTooltip]="tooltipText"
        matTooltipPosition="above"
        [class.on-hover]="nameTooltip"
        [class.show-box-shadow]="showBoxShadow"
        [style.width.px]="size"
        [style.height.px]="size"
        [style.border-radius]="borderRadius"
      />
    } @else {
      <div
        class="avatar-placeholder"
        [matTooltip]="tooltipText"
        matTooltipPosition="above"
        [class.on-hover]="nameTooltip"
        [class.show-box-shadow]="showBoxShadow"
        [style.width.px]="size"
        [style.height.px]="size"
        [style.fontSize.px]="placeholderFontSize"
        [style.background-color]="backgroundColor"
        [style.border-radius]="borderRadius"
      >
        {{ user?.getInitials() || fallbackInitials }}
      </div>
    }
    <div *ngIf="isUploader" class="uploader-icon">
      <i class="far fa-camera fa-2x"></i>
    </div>
  </div>
  @if (withName) {
    <span class="user-name" [class.text-dark-grey-50-opacity]="!isActive"> {{ user?.name || email || phone }} </span>
  }
</div>
